.video-background {
  position: relative;
  width: 100%;
  height: 500px;
}

.video-background__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.video-background__picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
  visibility: hidden;
  pointer-events: none;
}

.video-background__video::-webkit-media-controls {
  display: none;
  pointer-events: none;
}

.video-background__video::-webkit-media-controls-start-playback-button {
  display: none;
  pointer-events: none;
}

.video-background__video-blocker {
  position: absolute;
  height: 500px;
  width: 100%;
  top: 0;
  pointer-events: none;
  z-index: 10;
}

@media screen and (max-width: 1300px) {
  .video-background {
    height: 400px;
  }
  .video-background__video {
    pointer-events: none;
  }
  .video-background__video-blocker {
    height: 400px;
  }
}

@media screen and (max-width: 500px) {
  .video-background__video {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
  .video-background__picture {
    display: block;
    visibility: visible;
    pointer-events: all;
  }
}
