.gallery {
  width: 880px;
  max-width: calc(100% - 400px);
  box-sizing: border-box;
  position: relative;
  margin: 40px auto 80px;
  font-family: "Inter", Arial, sans-serif;
}

.gallery__header {
}

.gallery__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, max-content));
  justify-content: center;
  grid-gap: 20px 18px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.gallery__card {
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border-radius: 10px;
  }
}

.gallery__image {
  width: 282px;
  height: 282px;
  border-radius: 10px 10px 0 0;
}

.gallery__description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 22px 15px 22px 21px;
  border-radius: 0 0 10px 10px;
}

.gallery__title {
  max-width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 29px;
}

@media screen and (max-width: 2000px) {
  .gallery {
    max-width: 1180px;
    padding: 0;
  }
}

@media screen and (max-width: 1258px) {
  .gallery {
    max-width: calc(100% - 400px);
  }
}

@media screen and (max-width: 1070px) {
  .gallery {
    max-width: calc(100% - 200px);
  }
}

@media screen and (max-width: 480px) {
  .gallery {
    max-width: calc(100% - 38px);
  }
}
