.menu-item {
  height: auto;
  max-width: 670px;
  margin: 0 auto 150px 375px;
  display: flex;
  flex-direction: column;
  font-family: "Inclusive Sans", sans-serif, Arial;
}

.menu-item__title {
  margin: 40px auto 50px 0;
  font-size: 50px;
  color: #1f1717;
  font-family: "Roboto Condensed", sans-serif, Arial;
}

.menu-item__subtitle {
  margin: 0 auto 30px 0;
  color: #1f1717;
}

.menu-item__ul {
  display: flex;
  flex-direction: column;
  margin: 30px auto 0 0;
  padding: 0;
}

.menu-item__li {
  max-width: 850px;
  height: 160px;
  padding: 10px 0 10px;
  display: flex;
  flex-direction: row;
  border-bottom: #1f1717 1px solid;
  cursor: default;
}

.menu-item__li:hover {
  background-color: rgba(255, 255, 255, 0.685);
}

.menu-item__image {
  height: 130px;
  width: 268px;
  border-radius: 5%;
  margin: 0 15px 0 0;
}

.menu-item__text {
  width: 386px;
  padding: 0 20px 0 0;
  color: #1f1717;
}

.menu-item__buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: "Inclusive Sans", sans-serif, Arial;
}

.menu-item__popular {
  color: white;
  background-color: rgb(181, 143, 94);
  width: fit-content;
  height: 18px;
  padding: 1px 3px;
  border-color: rgb(181, 143, 94);
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  font-size: 16px;
  align-items: center;
  display: flex;
}

.menu-item__name {
  margin: 0;
  color: #1f1717;
  font-family: "Roboto Condensed", sans-serif, Arial;
  letter-spacing: 0.5px;
}

.menu-item__description {
  margin: 0;
  line-height: 1.5;
  color: #1f1717;
}

.menu-item__button {
  font-size: 20px;
  width: 200px;
  height: 57px;
  padding: 0;
  cursor: pointer;
  align-self: center;
  margin: 0;
}

.menu-item__show-more {
  background: transparent;
  display: inline-block;
  margin: 20px auto 20px;
  padding: 12px 24px;
  border: 1px solid #4f4f4f;
  border-radius: 4px;
  transition: all 0.2s ease-in;
  position: relative;
  overflow: hidden;
  font-size: 19px;
  color: black;
  z-index: 1;
  cursor: pointer;
}

.menu-item__show-more:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.menu-item__show-more:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: rgb(181, 143, 94);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.menu-item__show-more:hover {
  color: #ffffff;
  border: 1px solid rgb(181, 143, 94);
}

.menu-item__show-more:hover:before {
  top: -35%;
  background-color: rgb(181, 143, 94);
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.menu-item__show-more:hover:after {
  top: -45%;
  background-color: rgb(181, 143, 94);
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.menu-item__advisory {
  margin: 0 auto 80px 0;
  padding: 0;
}

.menu-item__advisory-show-more {
  margin: 20px auto 60px 0;
  padding: 0;
}

@media screen and (max-width: 1300px) {
  .menu-item {
    margin: 0 auto 150px 275px;
  }
}

@media screen and (max-width: 1100px) {
  .menu-item {
    margin: 0 auto 150px 175px;
  }
  .menu-item__li {
    max-width: 650px;
  }
}

@media screen and (max-width: 900px) {
  .menu-item {
    margin: 0 auto 150px 75px;
  }
  .menu-item__li {
    max-width: 650px;
  }
}

@media screen and (max-width: 800px) {
  .menu-item {
    margin: 0 auto 150px 25px;
  }
  .menu-item__li {
    max-width: 540px;
  }
}

@media screen and (max-width: 800px) {
  .menu-item {
    margin: 0 auto 150px 25px;
  }
  .menu-item__li {
    max-width: 540px;
  }
  .menu-item__subtitle {
    margin: 0 40px 30px 0;
  }
}

@media screen and (max-width: 700px) {
  .menu-item__li {
    max-width: 460px;
  }
  .menu-item__title {
    margin: 20px auto 10px 0;
  }
  .menu-tem__ul {
    margin: 20px auto 0 0;
  }
}

@media screen and (max-width: 500px) {
  .menu-item__li {
    max-width: 350px;
  }
  .menu-item__popular {
    width: max-content;
    font-size: 11px;
  }
  .menu-item__image {
    width: 128px;
  }
  .menu-item__advisory {
    margin: 0 20px 80px 0;
  }
  .menu-item__text {
    width: 180px;
  }
}

@media screen and (max-width: 400px) {
  .menu-item__li {
    max-width: 335px;
  }
}
