@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inclusive+Sans:ital@1&family=Playfair+Display:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inclusive+Sans:ital@1&family=Playfair+Display:wght@700&family=Tilt+Neon&display=swap");

@import url("/src/components/About/About.css");
@import url("/src/components/App/App.css");
@import url("/src/components/BookOnlineButton/BookOnlineButton.css");
@import url("/src/components/ContactForm/ContactForm.css");
@import url("/src/components/Dropdown/Dropdown.css");
@import url("/src/components/ExploreOurMenu/ExploreOurMenu.css");
@import url("/src/components/FeaturedSection/FeaturedSection.css");
@import url("/src/components/Footer/Footer.css");
@import url("/src/components/Gallery/Gallery.css");
@import url("/src/components/Header/Header.css");
@import url("/src/components/MenuItem/MenuItem.css");
@import url("/src/components/MenuPizza/MenuPizza.css");
@import url("/src/components/ModalContactForm/ModalContactForm.css");
@import url("/src/components/ModalTemplate/ModalTemplate.css");
@import url("/src/components/MultistepForm/MultiStepForm.css");
@import url("/src/components/VideoBackground/VideoBackground.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f0f0;
  font-family: "Inclusive Sans", sans-serif, Arial;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
