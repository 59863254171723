.explore {
  background-color: #f5f0f0;
  padding: 0 0 100px;
}

.explore__header {
  color: #1f1717;
  font-family: "Roboto Condensed", sans-serif, Arial;
  margin: 0 0 70px;
  text-align: center;
  letter-spacing: -1px;
  font-size: 40px;
}

.explore__items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.explore__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  color: rgb(181, 143, 94);
}

.explore__image-wrapper:hover ~ .explore__title {
  border-bottom: 3px solid rgb(181, 143, 94);
  transform: translate(0, -15px);
}

.explore__image-wrapper {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  transition: transform 0.2s ease-in;
}

.explore__image-wrapper:hover {
  transform: translate(0, -15px);
}

.explore__image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s ease-in;
}

.explore__image:hover {
  transform: scale(1.1);
}

.explore__title {
  margin: 10px 0 0;
  padding: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 3px solid transparent;
  transition: transform 0.2s ease-in;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  display: inline-block;
  color: black;
  font-family: "Inclusive Sans", sans-serif, Arial;
}

.explore__title:hover {
  border-bottom: 3px solid rgb(181, 143, 94);
  transition: all 0.5s ease;
}

@media screen and (max-width: 1300px) {
  .explore {
    padding: 0 20px 100px;
  }
  .explore__image-wrapper {
    width: 170px;
    height: 170px;
  }
  .explore__image {
    width: 170px;
    height: 170px;
  }
}

@media screen and (max-width: 500px) {
  .explore__items {
    gap: 40px;
  }
  .explore__image-wrapper {
    width: 130px;
    height: 130px;
  }
  .explore__image {
    width: 130px;
    height: 130px;
  }
  .explore__title {
    font-size: 14px;
  }
}
