.modal {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 3;
  visibility: hidden;
}

.modal__open {
  visibility: visible;
  opacity: 1;
}

.modal__form {
  width: 440px;
  border-radius: 20px;
  flex-direction: column;
  font-family: "Roboto Condensed", sans-serif, Arial;
  font-size: 30px;
  font-weight: bold;
  line-height: 20px;
  background: white;
  justify-content: space-between;
  display: flex;
  padding: 34px 36px 34px;
  position: absolute;
}

.modal__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 20px;
  color: #1a1b22;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 24px;
  font-weight: 900;
}

.modal__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: rgb(181, 143, 94);
  outline: 3px rgb(181, 143, 94) solid;
  outline-offset: -3px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: 400ms;
}

.modal__button-svg {
  height: 40px;
}

.modal__button-text {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
}

.modal__button .modal__button-text {
  color: white;
  font-weight: 700;
  transition: 400ms;
}

.modal__button .modal__button-svg path {
  transition: 400ms;
}

.modal__button:hover {
  background-color: transparent;
}

.modal__button:hover .modal__button-text {
  color: rgb(181, 143, 94);
}

.modal__button:hover .modal__button-svg path {
  fill: rgb(181, 143, 94);
}

.modal__button-close {
  background: url(../../images/close-button.svg) center no-repeat;
  height: 40px;
  width: 40px;
  border: none;
  background-color: transparent;
  position: absolute;
  top: -40px;
  right: -40px;
  cursor: pointer;
}

@media screen and (max-width: 670px) {
  .modal__form {
    width: 350px;
  }
}

@media screen and (max-width: 600px) {
  .modal__form {
    width: 200px;
  }
  .modal-submit__paragraph {
    font-size: 16px;
    margin: 0 0 20px;
  }
}
