.services-submenu {
  width: 10rem;
  position: absolute;
  list-style: none;
  text-align: start;
  padding: 0;
  top: 105px;
  z-index: 3;
}

.services-submenu.clicked {
  display: none;
}

.dropdown__li {
  background-color: rgba(21, 23, 23, 0.7);
  cursor: pointer;
}

.dropdown__li:last-child:hover {
  background-color: rgba(21, 23, 23);
  width: 10rem;
  color: rgb(181, 143, 94);
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
}

.dropdown__li:hover {
  background-color: rgba(21, 23, 23);
  width: 10rem;
  color: rgb(181, 143, 94);
}

.dropdown__li:last-child {
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
}

.nav-item {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: white;
  padding: 16px;
}

.nav-item:hover {
  color: rgb(181, 143, 94);
}
