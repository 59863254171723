.form__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form__indicator-container {
  display: flex;
  width: 340px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: row;
}

.form__indicator {
  background: url(../../images/blackCircle.svg) center no-repeat;
  border: none;
  background-color: transparent;
  color: #1f1717;
  padding: 0;
  height: 30px;
  width: 30px;
  font-size: 20px;
}

.form__indicator-active {
  background: url(../../images/goldCircle.svg) center no-repeat;
  height: 30px;
  width: 30px;
  border: none;
  background-color: rgb(181, 143, 94);
  border-radius: 50%;
  color: #f5f0f0;
  padding: 0;
  margin: 0;
  font-size: 20px;
}

.form__form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  gap: 30px;
  max-width: 450px;
  position: relative;
  padding: 2rem;
  margin: 0 auto 0;
  border-radius: 0.5rem;
  font-family: Arial, Helvetica, sans-serif;
  width: 500px;
}

.page-one {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.page-two {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.page-three {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.hidden {
  display: none;
  pointer-events: none;
}

.form__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form__buttons-first {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.form__button {
  height: 40px;
  background: rgb(181, 143, 94);
  width: 100px;
}

.form__button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: rgb(181, 143, 94);
  outline: 3px rgb(181, 143, 94) solid;
  outline-offset: -3px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: 400ms;
}

.form__button-svg {
  height: 40px;
}

.form__button-text {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
}

.form__button .form__button-text {
  color: white;
  font-weight: 700;

  transition: 400ms;
}

.form__button .form__button-svg path {
  transition: 400ms;
}

.form__button:hover {
  background-color: transparent;
}

.form__button:hover .form__button-text {
  color: rgb(181, 143, 94);
}

.form__button:hover .form__button-svg path {
  fill: rgb(181, 143, 94);
}

@media screen and (max-width: 500px) {
  .form__form {
    max-width: 380px;
  }
  .form__input-box {
    width: 380px;
  }
  .form__input {
    width: 304px;
  }
}

@media screen and (max-width: 400px) {
  .form__form {
    max-width: 350px;
  }
  .form__input-box {
    width: 348px;
  }
  .form__input {
    width: 274px;
  }
}
