@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");

.dropdown {
  position: relative;
}

.dropdown-menu {
  color: black;
  position: absolute;
  left: 0;
  top: calc(100% + 0.25rem);
  background-color: aqua;
  padding: 0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  pointer-events: none;
}

.header {
  height: 105px;
  background: #1f1717;
  padding: 0 40px 0;
  position: relative;
  z-index: 1;
}

.header__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 104px;
}

.header__logo {
  align-self: center;
  height: 90px;
  cursor: pointer;
}

.header__list {
  display: flex;
  list-style-type: none;
  padding: 0;
  gap: 40px;
  align-items: center;
  color: #f5f0f0;
  font-family: "Roboto Condensed", sans-serif, Arial;
  font-size: x-large;
}

.header__list-item-menu {
  cursor: pointer;
  transition: color 0.15s ease-in;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header__list-item-menu-hovered {
  cursor: pointer;
  transition: color 0.15s ease-in;
  color: white;
  height: 79px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 51px;
}

.header__list-item-menu-hovered:hover {
  cursor: pointer;
  color: rgb(181, 143, 94);
}

.header__list-item {
  cursor: pointer;
  transition: color 0.15s ease-in;
  color: white;
}

.header__list-item:hover {
  cursor: pointer;
  color: rgb(181, 143, 94);
}

.header__button {
  width: 200px;
  height: 50px;
  font-size: 17px;
  padding: 0;
  cursor: pointer;
  align-self: center;
}

.menu__container {
  display: none;
  visibility: hidden;
  pointer-events: none;
  height: 105px;
}

.navbar {
  background-color: transparent;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-bars {
  margin: 0;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #363031;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
}

.nav-menu__backdrop {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 3;
  visibility: hidden;
}

.nav-menu__backdrop__open {
  visibility: visible;
  opacity: 1;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0 8px 16px;
  list-style: none;
  height: 50px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: rgba(21, 23, 23, 0.7);
  color: rgb(181, 143, 94);
}

.nav-menu-items {
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 5;
  background-color: #363031;
}

.navbar-toggle {
  background-color: #363031;
  padding-left: 40px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.nav-menu__button {
  width: 210px;
  height: 50px;
  margin: 20px 0 0 20px;
  font-size: 18px;
}

@media screen and (max-width: 1100px) {
  .header {
    height: 90px;
  }
  .header__container {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
  .menu__container {
    display: block;
    visibility: visible;
    pointer-events: all;
    height: 90px;
  }
  .header__logo {
    height: 70px;
  }
}

@media screen and (max-width: 600px) {
  .nav-text {
    height: 50px;
  }
}

@media screen and (max-width: 400px) {
  .nav-text {
    height: 40px;
  }
}
