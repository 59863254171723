.featured {
  display: flex;
  flex-direction: row;
}

.featured__carousel {
  cursor: grab;
  overflow: hidden;
  border-bottom: solid #1f1717;
  border-top: solid #1f1717;
  width: 85%;
  margin: 0px auto 0px;
}

.featured__carousel::-webkit-scrollbar {
  display: none;
}

.featured__carousel__container {
  width: 50%;
  background-color: #f5f0f0;
  margin: 80px auto 80px;
}

.featured__carousel__inner {
  display: flex;
}

.featured__carousel__image-div {
  height: 500px;
  min-width: 400px;
  padding: 40px;
}

.featured__carousel__image {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  pointer-events: none;
}

.featured__text {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-content: center;
  flex-wrap: wrap;
  margin: 80px 50px 80px auto;
  padding-top: 40px;
}

.featured__header {
  padding: 0;
  font-size: 50px;
  line-height: 55px;
  letter-spacing: -2px;
  line-height: 55px;
  margin: 0 auto 40px 0;
  font-family: "Inclusive Sans", sans-serif, Arial;
}

.featured__paragraph {
  padding: 0;
  line-height: 45px;
  font-size: 25px;
  margin: 0 0 auto 0;
  font-family: "Inclusive Sans", sans-serif, Arial;
}

.featured__button {
  font-size: 25px;
  width: 311px;
  height: 75px;
  padding: 0;
  cursor: pointer;
}

@media screen and (max-width: 1300px) {
  .featured {
    flex-direction: column-reverse;
  }
  .featured__text {
    margin: 30px auto 40px;
    padding: 0;
    width: 80%; /* correct this to 100% */
  }
  .featured__header {
    font-size: 35px;
    margin: 0 auto 10px 0;
  }
  .featured__paragraph {
    font-size: 20px;
    margin: 0 auto 30px;
  }
  .featured__carousel__container {
    margin: 0 auto 40px;
    width: 100%;
  }
  .featured__carousel {
    width: 95%;
  }
  .featured__carousel__image-div {
    height: 400px;
    min-width: 300px;
  }
}

@media screen and (max-width: 700px) {
  .featured__text {
    align-items: center;
  }
}
