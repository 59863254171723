.contact-form__header {
  font-size: 70px;
  margin: 10px 0 40px;
  padding: 0;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif, Arial;
}

.contact-form__number-container {
  display: flex;
  justify-content: center;
  margin: 0 0 70px;
}

.contact-form__number-image {
}

.contact-form__number-image {
}

.contact-form__number {
  padding: 0;
  margin: 0;
  align-self: center;
}

.contact-form__seperator-container {
  position: relative;
  margin: 0 0 70px;
}

.contact-form__seperator {
  margin: 70px auto 70px;
}

.contact-form__seperator-text {
  margin: 0;
  padding: 0 20px 0;
  position: absolute;
  left: 51%;
  margin-left: -50px;
  top: 50%;
  margin-top: -20px;
  background: #f5f0f0;
}

.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin: 0 auto 60px;
  padding: 0;
  box-sizing: border-box;
  gap: 30px;
  max-width: 450px;
}

.form__header {
  margin: 0;
  text-align: center;
  font-size: 50px;
}

.form__input-box {
  position: relative;
  align-self: center;
  width: 450px;
}

.form__input {
  padding: 10px 0 10px 10px;
  width: 376px;
  margin-left: 2rem;
  border: 1px solid black;
  background-color: transparent;
  border-radius: 5px;
  outline: none;
  color: black;
  font-size: 1em;
  transition: 0.5s;
}

.form__input-span {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  margin-left: 2rem;
  pointer-events: none;
  font-size: 1em;
  color: black;
  text-transform: uppercase;
  transition: 0.5s;
}

.form__input-box .form__input:valid ~ .form__input-span,
.form__input-box .form__input:focus ~ .form__input-span {
  color: black;
  transform: translateX(10px) translateY(-7px);
  font-size: 0.65em;
  padding: 0 10px;
  background-color: #f5f0f0;
  border-left: 1px solid black;
  border-right: 1px solid black;
  letter-spacing: 0.2em;
}

.form__input-box .form__input:valid,
.form__input-box .form__input:focus {
  border: 1px solid black;
}

.form__label {
  display: flex;
  flex-direction: column;
}

.react-select__container {
  background: transparent;
  border: 1px black solid;
}

.react-select__control {
  background: transparent;
  border-radius: 0;
}

.css-13cymwt-control {
  background: transparent;
  border-radius: 0;
}

.react-select__value-container {
  font-size: large;
}

.react-select-control {
  border-radius: 0;
  background: transparent;
}

.form__submit {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0;
  gap: 3px;
  width: 140px;
  background-color: rgb(181, 143, 94);
  outline: 3px rgb(181, 143, 94) solid;
  outline-offset: -3px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: 400ms;
}

.form__submit-svg {
  height: 30px;
}

.form__submit-text {
  margin: 0;
  font-size: 20px;
}

.form__submit .form__submit-text {
  color: white;
  font-weight: 700;
  transition: 400ms;
}

.form__submit .form__submit-svg path {
  transition: 400ms;
}

.form__submit:hover {
  background-color: transparent;
}

.form__submit:hover .form__submit-text {
  color: rgb(181, 143, 94);
}

.form__submit:hover .form__submit-svg path {
  fill: rgb(181, 143, 94);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px #f5f0f0 inset;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 500px) {
  .contact-form__header {
    font-size: 50px;
  }
}
